<template>

    <div style="width: 100%; text-align: center; padding: 32px">
        <textarea v-model.trim="params.search" rows="10" style="width: 80%"/>
        <br>
        <br>
        <input type="text" class="kb-form-input" v-model.trim="params.acl">
    </div>

    <div style="text-align: center">
        <button class="kb-btn kb-btn-primary" @click="submitSearch"><span class="text">제출</span></button>
    </div>

    <div style="width: 100%; text-align: center; padding: 32px">
        <div class="kb-table kb-table-bordered-v2 kb-table-bordered-v2-min">
        <table>
            <thead>
            <tr>
                <th v-for="(header, index) in kb-table.headers" :key="index">
                    {{ header }}
                </th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(body, index) in table.bodies" :key="index">
                    <th v-for="(header, index) in table.headers" :key="index">
                        {{ body[header] }}
                    </th>
                </tr>
            </tbody>
        </table>
        </div>
    </div>

</template>
<script>
import {reactive} from 'vue';
import ApiService from '@/assets/js/api.service';

export default {
    name: 'Search',
    components: {},
    setup() {
        const params = reactive({
            search: '',
            acl: ''
        });

        const table = reactive({
            headers: [],
            bodies: [],
        });

        const submitSearch = () => {
            ApiService.post('/v1/app/auth/search', params).then(res => {
                if (res && res.length > 0) {
                    table.headers = Object.keys(res[0]);
                    table.bodies = res;
                } else {
                    table.headers = [];
                    table.bodies = [];
                }
            }).catch(err => {
                console.log(err);
            });
        };

        return {
            params,
            table,
            submitSearch,
        };
    },
};
</script>
